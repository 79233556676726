<template>
  <div>
    <div>
      <h2>近5年研究室代表性主持项目</h2>
      <hr />
      <!-- <h3>近5年研究室部分主持项目：</h3> -->
      <span v-for="project in projects" :key="project.content">
        <p>💻&nbsp&nbsp {{ project.content }}</p>
      </span>
    </div>
    
    <!-- 学术奖励栏 -->
    <!-- <div class="winning">
      <h2>学术奖励</h2>
      <hr />
      <span v-for="Academic in Academics" :key="Academic.award">
        <p>👏🏻&nbsp&nbsp&nbsp{{ Academic.award }}。</p>
      </span>
      <div class="dividing-lines" :class="journal"></div>
      <span v-for="winning in winnings" :key="winning.award">
        <p>🏆&nbsp&nbsp&nbsp{{ winning.award }}</p>
      </span>
    </div> -->
    <div class="winning">
      <h2>授权专利</h2>
      <hr />
      <span v-for="(member, index) in members" :key="index">
        <p>
          📃&nbsp&nbsp&nbsp{{ 28 - index }}、{{ member.ieee }}“<u>{{
            member.Patents
          }}</u
          >”，{{ member.time }}
        </p>
      </span>
    </div>
    <div class="winning">
      <h2>实验室资料</h2>
      <hr />
      <el-button type="primary" icon="el-icon-search" @click="login"
        >获取资料</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Research",
  data() {
    return {
      projects: [
        {
          content:
            "新重庆青年创新人才项目，面向应急网络的IOS辅助无人机能感通资源智能管理研究，2025.1-2027.12，30万元",
        },
        {
          content:
            "工信部产业基础再造和制造业高质量发展专项课题，重特大数据安全事件智能发现与有效响应技术，2024.9-2026.7，271.2万元",
        },
        {
          content:
            "重庆市杰出青年科学基金，边缘智能驱动的通感算一体化资源管控，2024.7-2026.6，50万元",
        },
        {
          content:
            "GF创新特区重点项目课题，空地复杂传播环境XXXX技术，2023.12-2025.10，340万元",
        },
        {
          content:
            "国家青年拔尖人才，2024.1-2026.12，190万元",
        },
        {
          content:
            "JKWxxx工程项目, 面向5G控制面协议的******技术，2023-12至2024-11，145万元",
        },
        {
          content:
            "国家自然科学基金面上项目，基于智简协同的泛在边缘网络资源优化技术研究，2023-01至2026-12，54万元",
        },
        {
          content:
            "JKWxxx工程项目, 移动通信信令网XXXX生成与验证技术，2022-12至2023-12，360万元",
        },
        {
          content:
            "重庆市科技局技术创新与应用发展重点专项, 基于XXXX的“感—通—算”一体化智能管控系统设计与实现，2022-12至2024-12，70万元",
        },
        {
          content:
            "工信部2021年产业基础再造和制造业高质量发展专项项目,面向异构安全能力的5G安全自动化响应系统——“5G安全数据智能分析”课题, 2021-09 至 2023-09, 315万元",
        },
        {
          content:
            "北斗智谷（北京）网络技术有限公司，企业委托项目，移动边缘网络实时信息处理与态势感知平台开发，2021-01至2022-12，108万元",
        },
        {
          content:
            "中国科协, 中国科协青年人才托举工程, 面向车辆边缘网络的智能资源调度, 2021-01至 2023-12, 45万元",
        },
        {
          content:
            "重庆英才—青年拔尖人才，移动物联网通信和计算资源管理与优化， 2021-01至2023.12，40万元",
        },
        {
          content:
            "国家自然科学基金面上项目，面向5G车联网的边缘网络资源管理关键技术研究， 2020-01至2023-12，59万元 ",
        },
      ],
      Academics: [
        
      ],
      /*winnings: [
        {
          award: "王小洁入选科睿唯安全球高被引科学家（2023年）。",
        },
        {
          award: "宁兆龙入选科睿唯安全球高被引科学家（2020-2023年）。",
        },
        {
          award: "宁兆龙参与的成果获甘肃省自然科学奖特等奖（2022年）。",
        },
        {
          award: "宁兆龙牵头的成果获中国产学研合作创新成果一等奖（2022年）。",
        },
        {
          award: "宁兆龙获重庆市十佳科技青年奖（2022年）。",
        },
        {
          award: "宁兆龙入选科睿唯安全球高被引科学家（2022年）。",
        },
        {
          award: "宁兆龙入选全球AI 2000人工智能最具影响力学者（2022年）。",
        },
        {
          award: "宁兆龙入选重庆市电子学会五佳优秀科技工作者（2022年，排名第一）。",
        },
        {
          award: "宁兆龙入选爱思唯尔中国高被引学者（2020-2022年）。",
        },
        {
          award: "王小洁入选中国科协“青年人才托举工程”（2021年）。",
        },
        {
          award: "宁兆龙荣获IEEE车辆技术协会年度最佳陆地交通论文奖（2020年全球唯一）。",
        },
        {
          award: "王小洁获ACM优博（2019年，大连分会）。",
        },
        {
          award: "宁兆龙荣获IEEE系统协会2019年度最佳论文奖。",
        },
  
        {
          award: "宁兆龙入选中国科协“青年人才托举工程”（2018年）。",
        },
        {
          award: "宁兆龙获香江学者奖（2018年）。",
        },  
        {
          award: "宁兆龙荣获中国百篇最具影响国际学术论文（2018年）。",
        },  
        {
          award:
            "宁兆龙作为第一完成人荣获辽宁省自然科学学术成果一等奖2次（2017和2019年）",
        },
        {
          award: "宁兆龙入选ACM学术新星（2017年，大连分会）。",
        },
        {
          award: " 宁兆龙获ACM优博（2015年，大连分会）",
        },
        // 待修改
        // {
        //   award:
        //     "荣获辽宁省自然科学学术成果一等奖3次（2次第一、1次第二完成人）。",
        // },
      ],*/
      members: [
        {
          ieee: "宁兆龙;冀宏婧;王小洁;陈博宇;亓伟敬;宋清洋;郭磊，",
          Patents: "可穿戴设备充电网络中的用户调度和无人机高度控制方法",
          time: "授权号ZL202210404642.2，授权日期：2024.12.10。",
        },
        {
          ieee: "宁兆龙; 朱帅琦; 王小洁; 亓伟敬; 宋清洋; 郭磊，",
          Patents: "一种基于强化学习的陆空联合轨迹优化与资源分配方法",
          time: "授权号ZL202210089325.6，授权日期:2024.7.23。",
        },
        {
          ieee: "董沛然; 宁兆龙; 王小洁; 郭磊; 高新波，",
          Patents: "一种基于无人机计算卸载的路径规划和任务调度方法",
          time: "授权号ZL202110624481.3，授权日期2023.05.30。",
        },
        {
          ieee: "宁兆龙; 陈晗頔; 王小洁; 郭磊; 高新波，",
          Patents: "基于区块链使能的智能电网电力故障检测与安全传输方法",
          time: "授权号ZL202110625902.4，授权日期2023.4.7。",
        },
        {
          ieee: "陈晗頔; 王小洁; 宁兆龙; 亓伟敬; 宋清洋; 郭磊; 陈博宇，",
          Patents: "一种基于轻量级学习的实时服务迁移方法，",
          time: "授权号ZL202210921760.0，授权日期2024.03.19。",
        },
        {
          ieee: "宁兆龙，杨雨轩，王小洁，郭磊，高新波，",
          Patents: "无人机协助移动边缘计算中的动态计算卸载和服务器部署方法",
          time: "授权号ZL202110633417.1，授权日期2023-11-21。",
        },
        {
          ieee: "宁兆龙，董沛然，郭毅，胡希平，王小洁，",
          Patents: "一种分布式的5G医疗物联网健康监测方法",
          time: "授权号ZL201911353250.2，授权日期:2022-11-18。",
        },
        {
          ieee: "宁兆龙，张凯源，王小洁，董沛然，孙守铭，",
          Patents: "一种车联网中的智能边缘计算方法",
          time: "授权号ZL201911354245.3，授权日期2022-11-18。",
        },
        {
          ieee: "董沛然，宁兆龙，王小洁，郭磊，",
          Patents: "一种基于移动边缘计算的分布式动态服务部署方法",
          time: "授权号ZL202011251735.3，授权日期2022-11-18。",
        },
        {
          ieee: "王小洁，宁兆龙，郭磊，高新波，",
          Patents: "基于无线能量驱动的移动边缘计算分布式服务部署方法",
          time: "授权号ZL202110625127.2，授权日期2022-08-26。",
        },
        {
          ieee: "王小洁，宁兆龙，郭磊，高新波，",
          Patents: "一种基于差异化服务的无人机资源动态部署方法",
          time: "ZL202110625142.7，授权日期:授权日期2022-08-23。",
        },
        {
          ieee: "宁兆龙，孙兰芳，王小洁，胡希平，郭毅，",
          Patents: "一种基于图论和机器学习的神经症特征提取方法",
          time: "授权号:ZL202110625900.5，授权日期:2022-04-18。",
        },
        {
          ieee: "亓伟敬，宋清洋，郭磊，",
          Patents: "一种基于车辆轨迹预测的异构车联网切换方法",
          time: "授权号:ZL202010783220.1，授权日期:2022.03。",
        },
        {
          ieee: "宁兆龙，于硕，",
          Patents: "基于社交网络的社团划分方法、装置、存储介质及设备",
          time: "授权号:ZL201810408325.1，授权日期:2021-12-14。",
        },
        {
          ieee: "宁兆龙，张凯源，王小洁，董沛然，孙守铭，",
          Patents: "一种车联网中的智能资源分配方法",
          time: "授权号：ZL201910448363.4，授权日期：2021-06-23。",
        },
        {
          ieee: "宁兆龙，董沛然，王小洁，夏锋，",
          Patents: "一种车载网络下的智能计算卸载方法",
          time: "授权号：ZL201910029128.3，授权日期：2021-05-28。",
        },
        {
          ieee: "宁兆龙，董沛然，王小洁，张凯源，孙守铭，",
          Patents: "一种5G车联网部分计算卸载方法",
          time: "授权号：ZL201911353266.3，授权日期：2021-05-14。",
        },
        {
          ieee: "宁兆龙，孙守铭，王小洁，张凯源，董沛然，",
          Patents: "一种基于区块链使能的群智感知的智能交通管理方法",
          time: "授权号：ZL201911353245.1，授权日期：2021-05-13。",
        },

        {
          ieee: "宁兆龙，陈晗頔，孙守铭，王小洁，卢国旭，",
          Patents: "一种车联网资源融合的车载任务协作迁移方法",
          time: "授权号：ZL202010711281.7，授权日期：2021-05-07。",
        },
        {
          ieee: "宁兆龙，董沛然，孔祥杰，夏锋，",
          Patents: "一种基于混合云计算的网络卸载方法",
          time: "授权号：ZL201810767054.9，授权日期：2021-04-21。",
        },
        {
          ieee: "宁兆龙，孙守铭，王小洁，张凯源，董沛然，",
          Patents: "一种共享巴士的动态车辆调度和线路规划方法",
          time: "授权号：ZL201911354241.5，授权日期：2021-03-29。",
        },
        {
          ieee: "亓伟敬，宋清洋，郭磊，",
          Patents: "超密度异构融合网络中区分业务类型的传输路径计算方法",
          time: "授权号:ZL201810081745.3，授权日期:2021.02。",
        },
        {
          ieee: "宁兆龙，",
          Patents: "信息处理方法、装置和系统",
          time: "授权号：ZL201710240628.2，授权日期：2020-11-06。",
        },
        {
          ieee: "宋清洋，林鹏，",
          Patents: "超密集蜂窝与D2D异构融合网络缓存优化决策方法",
          time: "授权号：ZL201810309517.7，授权日期：2020.11。",
        },
        {
          ieee: "宁兆龙，肖心茹，徐博，郭腾，张骁雄，夏锋，",
          Patents: "一种基于级联的论文影响力评估方法",
          time: "授权号：ZL201811072360.7，授权日期：2020-10-28。",
        },
        {
          ieee: "邱铁，宁兆龙，张玉爽，李明楚，陈宁，李晓芳，",
          Patents: "一种用于无线传感器网的时间同步方法",
          time: "授权号：ZL201610221092.5，授权日期：2019-03-05。",
        },
        {
          ieee: "宁兆龙，刘鑫童，杨卓，白晓梅，崔海，夏锋，",
          Patents: "一种基于学术大数据的会议影响力评估方法",
          time: "授权号：ZL201610874709.3，授权日期：2018-10-16。",
        },
        {
          ieee: "陈志奎，宁兆龙，凌若川，冷泳林，",
          Patents: "一种车载网中基于服务质量感知的接入方法",
          time: "授权号：ZL201510338812.1，授权日期：2018-10-16。",
        },
        // {
        //   ieee: "于尧，宋清洋，张杰，朱学艳，",
        //   Patents: "一种基于互信息量的监测路由器选择方法",
        //   time: "授权号:ZL201510253236.0，授权日期:2018.05。",
        // },
        // {
        //   ieee: "宋清洋，于尧，彭玉怀，张璐，吴晗，张鑫，",
        //   Patents: "一种协作无线网络中期限感知的自适应数据包传输方法",
        //   time: "授权号：ZL201410842685.4，授权日期:2017.11。",
        // },
        // {
        //   ieee: "宋清洋，岳明宏，代梦凡，吴晗，",
        //   Patents: "基于动态区域扩展和功率控制的异构网络干扰协调方法",
        //   time: "授权号:ZL201310689596.6，授权日期:2016.10。",
        // },
        // {
        //   ieee: "宋清洋，周厉宁，李勋，丛笑竹，",
        //   Patents: "基于MIMO和PNC融合的无线多跳路由网络传输方法",
        //   time: "授权号ZL201310682618.6，授权日期2016.06。",
        // },
        // {
        //   ieee: "宋清洋，黄洋，庄建华，崔亚运，",
        //   Patents: "自适应选择中继方案的中继传输系统与方法",
        //   time: "授权号:ZL201210319554.9，授权日期:2015.03。",
        // },
        // {
        //   ieee: "宋清洋，崔亚运，黄洋，",
        //   Patents: "基于物理层网络编码非同步叠加信号解码及方法",
        //   time: "授权号:ZL201210316013.0，授权日期:2014.11。",
        // },
      ],
    };
  },
  methods: {
    login() {
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.winning {
  /* padding: 20px 0 10px 20px; */
  /* width: 100%; */
  /* height: 5000; */
  /* background-color: rgb(215, 218, 221); */
  margin-top: 40px;
}
.el-button {
  margin: auto;
  padding: auto;
}
.dividing-lines {
  position: relative;
  height: 10px;
}
</style>
