<template>
  <div>
    <el-row :gutter="60">
      <!-- 分成24等分 -->
      <!-- 自我介绍 -->
      <el-col :md="24" :sm="24" :xs="24">
        <div class="col-md-8">
          <div>
            <br />
            <h2> 欢迎访问智能网络与安全研究室！ </h2><br />
            <p class="lab-introduciton">
              研究室成立于2021年9月，聚焦无线网络优化与安全方面的研究，聚焦应急网络和智能优化，围绕无线异构资源（通信、计算、存储、时间、空间等）协同管理中的关键问题，开展原创性研究和应用，构建未来网络感知、通信传输、高效计算、在线决策和内生安全的闭环网络优化生态体系架构，实现数字世界和物理世界的深度融合。
              <br>
            </p>
            <p class="lab-introduciton">
              具体研究方向包括：智能通信与计算、5G通信网安全、空地协同的自组网、可重构智能超表面无线通信等。
            </p>
            <br>
            <p class="recruit" >
              📢&nbsp研究室常年招聘青年教师，欢迎志同道合的小伙伴们联系我们！招聘待遇请见文档。
              <a href="https://iicns.cqupt.edu.cn/" style="font-size:10px">招聘待遇</a>
              <br>
              📢&nbsp研究室招募有志向逆袭的博士生和研究生，数学和编程基础功底很重要，但更重要的是有决心、有想法、有执行力！有意者请查阅研究室各位老师的联系方式。
            </p>
            <!-- <p style="color:blue;line-height:32px;font-size:17px">
                &#9835; 研究室招募有志向逆袭的博士生和研究生，数学和编程基础功底很重要，但更重要的是有决心、有想法、有执行力！有意者请查阅研究室各位老师的联系方式。
                <br />
            </p> -->
          </div>

          <!-- 分割线 -->
          <!-- <div class="emoji">🌝 🌞 ⭐ 🌟 🌠</div> -->
          <div class="dividing-lines"></div>

          <!-- news -->

          <br />
          <b>
            <div class="icon">🚀</div>
            最新动态
          </b>
          <br />
          <br />

          <p v-for="(item, index) of news" :key="index" class="news-content">
            👏
            <!-- {{ item.eng }}
            <br /> -->
            <a :href="item.newUrl" style="text-decoration: none; color: #000" target="blank">
              <b>
                {{ item.ch }}
              </b>
            </a>
          </p>

          ......
        </div>
      </el-col>

      <!-- 右边学术经历
      <el-col :md="8" :sm="12" :xs="24">
        <div class="academic-positions">
          <h4 class="with-underline">Academic Positions 学术经历</h4>
          <ul
            class="with-shaded-label"
            v-for="(academic, index) in positions"
            :key="index"
          >
            <li>
              <h3 class="title">
                <span class="shaded-label">{{ academic.year }}</span
                >{{ academic.position }}
              </h3>
              <div class="description">
                <p>
                  {{ academic.schoolEng }}, <b>{{ academic.universityEng }}</b
                  >, {{ academic.countryEng }}<br />
                  {{ academic.schoolCh }}，<b>{{ academic.universityCh }}</b
                  >，{{ academic.countryCh }}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </el-col> -->
    </el-row>

    <!-- 分割线 -->
    <div class="dividing-lines"></div>

    <!-- 荣誉奖励开始 -->
    <div class="subTitle">
      <h3>荣誉奖励</h3>
    </div>
    <el-row :gutter="30">
      <!-- 响应式布局，改变不同尺寸屏幕下span的大小，实现每行的不同显示结果
       :md 992 ="6":md="12" :sm 768 ="12" :xs <768 ="24" -->
      <el-col v-for="(award, index) in awards" :key="index" :lg="6" :sm="12" :xs="24">
        <!-- #####Begin iconbox v4-->
        <div class="icon-box ib-v4">
          <i class="el-icon-collection"></i>
          <h6 class="title with-shaded-label">
            <span class="shaded-label">{{ award.year }}</span>
            <!-- {{ award.titleEng }}
            <br /> -->
            {{ award.titleCh }}
          </h6>
          <!--<p>Phasellus lectus sem, egestas eget sollicitudin ut, lobortis ac nulla. Phasellus lectus sem, egestas eget sollicitudin ut.</p>-->
        </div>
        <!-- #####End iconbox v4-->
      </el-col>
    </el-row>
    <!-- 科研奖励结束 -->

    <!-- 分割线 -->
    <div class="dividing-lines"></div>

    <!-- 科研平台 -->
    <div class="subTitle">
      <h3>科研平台</h3>
    </div>
    <!-- 走马灯效果开始 -->
    <el-carousel :interval="3000" type="card" height="40vh" class="award-el-carousel">
      <el-carousel-item class="award-carousel-item" v-for="(item, index) in photos" :key="index" style="margin: 0px">
        <div class="awardPhoto-box">
          <el-image :src="item.photoUrl" :preview-src-list="Array(item.photoUrl)" class="image"></el-image>
          <!-- <img :src="item.photoUrl"/> -->
        </div>
      </el-carousel-item>
    </el-carousel>

    <!-- 屏幕小于450时显示基础走马灯效果 -->
    <el-carousel :interval="3000" class="small-award-el-carousel" arrow="always">
      <el-carousel-item class="award-carousel-item" v-for="(item, index) in photos" :key="index" style="margin: 0px">
        <div class="awardPhoto-box">
          <img :src="item.photoUrl" />
        </div>
      </el-carousel-item>
    </el-carousel>
    <!-- 小屏走马灯效果 -->
    <!-- 代表论文 -->
  </div>
</template>

<script>
export default {
  name: "CommonHome",
  data() {
    return {
      /* 新闻 */
      news: [
        {
          ch: '2025年3月，祝贺宁兆龙教授入选ScholarGPS全球前0.05%顶尖学者榜单（交通管理领域全球位列第2位）！',
        },
        {
          ch: '2025年3月，祝贺宁兆龙教授连续5年入选爱思唯尔中国高被引学者！',
        },
        {
          ch: '2025年3月，祝贺宁兆龙教授入选IEEE杰出讲师！',
        },
        {
          newUrl: './static/68.Mobility-aware_Seamless_Service_Migration_and_Resource_Allocation_in_Multi-edge_IoV_Systems.pdf',
          ch: '2025年2月，祝贺我们的论文“Mobility-aware Seamless Service Migration and Resource Allocation in Multi-edge IoV Systems”被IEEE TMC录用！',
        },
        {
          newUrl: './static/67.6G_Communication_New_Paradigm_The_Integration_of_Unmanned_Aerial_Vehicles_and_Intelligent_Reflecting_Surfaces.pdf',
          ch: '2025年1月，祝贺我们的论文“6G Communication New Paradigm: The Integration of Unmanned Aerial Vehicles and Intelligent Reflecting Surfaces”被IEEE COMST录用！',
        },
        {
          ch: '2024年12月，祝贺王小洁教授获批新重庆创新人才项目！',
        },
        {
          ch: '2024年11月，祝贺宁兆龙重庆青年专家工作室获批！',
        },
        {
          ch: '2024年11月，祝贺宁兆龙和王小洁教授连续入选科睿唯安全球高被引科学家！',
        },
        {
          newUrl: './static/66.A Survey on Security of UAV Swarm Networks Attacks and Countermeasures.pdf',
          ch: '2024年11月，祝贺我们的论文“A Survey on Security of UAV Swarm Networks: Attacks and Countermeasures”被ACM CSUR录用！',
        },
        {
          newUrl: './static/65.Joint_Optimization_of_Data_Acquisition_and_Trajectory_Planning_for_UAV-Assisted_Wireless_Powered_Internet_of_Things.pdf',
          ch: '2024年10月，祝贺我们的论文“Joint Optimization of Data Acquisition and Trajectory Planning for UAV-Assisted Wireless Powered Internet of Things”被IEEE TMC录用！',
        },
        {
          ch: '2024年10月，祝贺宁兆龙教授入选重庆市第四批学术技术带头人！',
        },
        {
          ch: '2024年9月，祝贺宁兆龙和王小洁教授连续五年入选斯坦福全球前2%顶尖科学家榜单！',
        },
        {
          ch: '2024年9月，祝贺宁兆龙教授入选中国计算机学会杰出会员！',
        },
        {
          newUrl: './static/64.Energy_Efficiency_Optimization_of_IRS_and_UAV-Assisted_Wireless_Powered_Edge_Networks.pdf',
          ch: '2024年8月，祝贺我们的论文“Energy Efficiency Optimization of IRS and UAV-Assisted Wireless Powered Edge Networks”被IEEE JSTSP录用！',
        },
        {
          ch: '2024年8月，祝贺宁兆龙教授入选强国青年科学家引领计划！',
        },
        {
          ch: '2024年8月，祝贺易令博士获批国家自然科学基金青年科学基金！',
        },
        {
          ch: '2024年8月，祝贺宁兆龙教授获批工信部重大专项子课题！',
        },
        {
          newUrl: './static/63.A Survey on Trustworthy Edge Intelligence From Security and Reliability To Transparency and Sustainability.pdf',
          ch: '2024年8月，祝贺我们的论文“A Survey on Trustworthy Edge Intelligence: From Security and Reliability To Transparency and Sustainability”被IEEE COMST录用！',
        },
        {
          newUrl: './static/62.Traffic-aware Lightweight Hierarchical Offloading towards Adaptive Slicing-enabled SAGIN.pdf',
          ch: '2024年8月，祝贺我们的论文“Traffic-aware Lightweight Hierarchical Offloading towards Adaptive Slicing-enabled SAGIN”被IEEE JSAC录用！',
        },
        {
          ch: '2024年7月，祝贺宁兆龙教授获批重庆市杰出青年基金项目！',
        },
        {
          ch: '2024年6月，祝贺宁兆龙教授入选英国工程技术协会会士（IET Fellow）！',
        },
        {
          newUrl: './static/61.Joint_User_Association_Interference_Cancellation_and_Power_Control_for_Multi-IRS_Assisted_UAV_Communications.pdf',
          ch: '2024年5月，祝贺我们的论文“Joint User Association, Interference Cancellation and Power Control for Multi-IRS Assisted UAV Communications”被IEEE TWC录用！',
        },
        {
          newUrl: './static/60.Integration of Sensing, Communication and Computing for Metaverse-A Survey.pdf',
          ch: '2024年4月，祝贺我们的论文“Integration of Sensing, Communication and Computing for Metaverse: A Survey”被ACM Computing Surveys录用！',
        },
        {
          ch: '2024年4月，祝贺宁兆龙教授连续四年入选中国高被引学者！',
        },
        {
          ch: '2024年4月，热烈祝贺宁兆龙教授当选EAI Fellow!',
        },
        {
          ch: '2024年4月，祝贺宁兆龙教授受聘为国防科技大学兼职教授！',
        },
        {
          ch: '2024年1月，祝贺王小洁教授获批GF特区重点项目课题；',
        },
        {
          ch: '2024年1月，祝贺宁兆龙教授担任校学术委员会委员!',
        },
        {
          ch: '2023年11月，热烈祝贺宁兆龙教授入选国家青年拔尖人才！',
        },
        {
          newUrl:'https://xwzx.cqupt.edu.cn/cqupt_xwzx/news.jsp?id=19S89P2Z97K84965',
          ch: '2023年11月，热烈祝贺宁兆龙和王小洁教授入选2023年度全球高被引科学家！',
        },
        {
          // newUrl: './static/51.TMC-Mean.pdf',
          ch: '2023年10月，祝贺宁兆龙和王小洁教授连续四年入选斯坦福全球前2%顶尖科学家榜单！',
        },
        {
          newUrl: './static/58.Joint_Optimization_of_Preference-Aware_Caching_and_Content_Migration_in_Cost-Efficient_Mobile_Edge_Networks.pdf',
          ch: '2023年10月，祝贺我们的论文:“Joint Optimization of Preference-Aware Caching and Content Migration in Cost-Efficient Mobile Edge Networks”被IEEE TWC录用！',
        },
        {
          newUrl: './static/57.Multi-Agent_Deep_Reinforcement_Learning_Based_UAV_Trajectory_Optimization_for_Differentiated_Services.pdf',
          ch: '2023年10月，祝贺我们的论文:“Multi-agent deep reinforcement learning based uav trajectory optimization for differentiated services”被IEEE TMC录用！',
        },
        {
          newUrl: './static/59.Wireless Powered Metaverse Joint Task Scheduling and Trajectory Design for Multi-Devices and Multi-UAVs.pdf',
          ch: '2023年9月，祝贺我们的论文:“Wireless Powered Metaverse: Joint Task Scheduling and Trajectory Design for Multi-Devices and Multi-UAVs”被IEEE JSAC录用！',
        },
        {
          newUrl: './static/56.Blockchain_Intelligence_for_Internet_of_Vehicles_Challenges_and_Solutions.pdf',
          ch: '2023年8月，祝贺我们的论文:“Blockchain Intelligence for Internet of Vehicles: Challenges and Solutions”被IEEE COMST录用！',
        },
        {
          ch: '2023年7月，热烈祝贺宁兆龙教授参与完成的成果获2022年度甘肃省自然科学奖特等奖（当年度三大奖唯一特等奖）! ',
        },
        {
          newUrl: './static/55. Mobile Edge Computing and Machine Learning in The Internet of Unmanned Aerial Vehicles_ A Survey.pdf',
          ch: '2023年6月，祝贺我们的论文:“Mobile Edge Computing and Machine Learning in The Internet of Unmanned Aerial Vehicles: A Survey”被ACM Computing Surveys录用！',
        },
        {
          ch: '2023年3月，祝贺宁兆龙教授连续三年入选中国高被引学者！',
        },
        {
          ch: '2023年2月，热烈祝贺宁兆龙教授牵头完成的成果获2022年度中国产学研合作创新成果一等奖（具备提名、推荐国家科学技术奖资格）!',
        },
        {
          newUrl: './static/54.Lightweight_Imitation_Learning_for_Real-Time_Cooperative_Service_Migration.pdf',
          ch: '2023年1月，祝贺我们的论文:“Lightweight Imitation Learning for Real-Time Cooperative Service Migration”被IEEE TMC录用！',
        },
        {
          newUrl: './static/53.Wireless_Powered_Mobile_Edge_Vomputing_Networks_A_Survey.pdf',
          ch: '2023年1月，祝贺我们的论文:“Wireless Powered Mobile Edge Computing Networks: A Survey”被ACM Computing Surveys录用！',
        },
        {
          newUrl: 'https://xwzx.cqupt.edu.cn/cqupt_xwzx/news.jsp?id=V17B26O8E7855105',
          ch: '2022年12月，祝贺宁兆龙教授获2022年度重庆市十佳科技青年奖！',
        },
        {
          ch: '2022年11月，祝贺宁兆龙教授连续三年入选全球高被引科学家！',
        },
        {
          newUrl: './static/52.QoE-Driven_Distributed_Resource_Optimization_for_Mixed_Reality_in_Dynamic_TDD_Systems.pdf',
          ch: '2022年9月，祝贺我们的论文:“QoE-Driven Distributed Resource Optimization for Mixed Reality in Dynamic TDD Systems”在IEEE TCOM发表！',
        },
        {
          newUrl: './static/51.TMC-Mean.pdf',
          ch: '2022年6月，祝贺我们关于移动区块链和边缘计算的论文："Mean-Field Learning for Edge Computing in Mobile Blockchain Networks"在IEEE TMC正式发表！',
        },
        {
          newUrl: 'https://rlsbj.cq.gov.cn/zwxx_182/gsxx/202204/t20220420_10635692.html',
          ch: '2022年5月，祝贺宁兆龙教授荣获获重庆青年五四奖章！',
        },
        {
          newUrl: 'https://xwzx.cqupt.edu.cn/cqupt_xwzx/news.jsp?id=145O4B4V62504K92',
          ch: '2022年4月，祝贺宁兆龙教授入选爱思唯尔2021年度中国高被引学者！',
        },
        {
          newUrl: 'https://xwzx.cqupt.edu.cn/cqupt_xwzx/news.jsp?id=145O4B4V62504K92',
          ch: '2022年4月，祝贺宁兆龙教授入选2022 AI 2000 Most Influential Scholar Honorable Mention in Internet of Things（物联网领域全球排名前100位）！',
        },
        {
          newUrl: './static/50.TMC-Partial-2022.pdf',
          ch: '2022年4月，祝贺我们关于5G车联网中部分计算卸载和自适应调度的论文: "Partial Computation Offloading and Adaptive Task Scheduling for 5G-Enabled Vehicular Networks"在IEEE TMC正式发表！',
        },
        {
          newUrl: './static/49.Online_Scheduling_and_Route_Planning_for_Shared_Buses_in_Urban_Traffic_Networks.pdf',
          ch: '2022年4月，祝贺我们的论文: "Online Scheduling and Route Planning for Shared Buses in Urban Traffic Networks"在IEEE TITS正式发表！',
        },
        {
          newUrl: './static/48.TMC-XJ-Imitation.pdf',
          ch: '2022年2月，祝贺我们基于模仿学习的在线车辆边缘计算任务调度的论文: "lmitation Learning Enabled Task Scheduling for Online Vehicular Edge Computing"在IEEE TMC正式发表！',
        },
        {
          newUrl: './static/44.JSTSP-Blockchain.pdf',
          ch: '2022年1月，祝贺我们关于区块链的智能电网安全传输的论文: "Blockchain-Enabled Electrical Fault Inspection and Secure Transmission in 5G Smart Grids"在IEEE JSTSP正式发表！',
        },
        {
          newUrl: './static/47.Online_Learning_for_Distributed_Computation_Offloading_in_Wireless_Powered_Mobile_Edge_Computing_Networks.pdf',
          ch: '2021年11月，祝贺我们关于无线协能边缘计算网络分布式卸载的论文: "Online Learning for Distributed Computation Offloading in Wireless Powered Mobile EdgeComputing Networks"被IEEE TPDS录用！',
        },
        {
          newUrl: './static/41.TMC-Dynamic.pdf',
          ch: '2021年11月，祝贺我们的论文: "Dynamic Computation Offloading and Server Deployment for UAV-Enabled Multi-Access Edge Computing"在IEEE TMC正式发表！',
        },
        {
          newUrl: './static/35.Dynamic_UAV_Deployment_for_Differentiated_Services_A_Multi-Agent_Imitation_Learning_Based_Approach.pdf',
          ch: '2021年11月，祝贺我们的论文: "Dynamic UAV Deployment for Differentiated Services: A Multi-Agent Imitation Learning Based Approach"在IEEE TMC正式发表！',
        },
        {
          newUrl: './static/42.JSAC-5G.pdf',
          ch: '2021年11月，祝贺我们的论文: "5G-Enabled UAV-to-Community Offloading: Joint Trajectory Design and Task Scheduling"在IEEE JSAC正式发表！',
        },
        {
          newUrl: '',
          ch: '2021年11月，祝贺宁兆龙教授入选2021年度科睿唯安全球高被引科学家！',
        },
        {
          newUrl: './static/TITS-Joint Computing.pdf',
          ch: '2021年8月，祝贺我们的论文: "Joint Computing and Caching in 5G-Envisioned Internet of Vehicles: A Deep Reinforcement Learning-Based Traffic Control System"在IEEE TITS正式发表！',
        },
        {
          newUrl: './static/40.TPDS-Ning.pdf',
          ch: '2021年6月，祝贺我们的论文: "Distributed and Dynamic Service Placement in Pervasive Edge Computing Networks"在IEEE TPDS正式发表！',
        },
        {
          newUrl: './static/39.TMC-Blockchain.pdf',
          ch: '2021年5月，祝贺我们的论文: "Blockchain-enabled Intelligent Transportation Systems: A Distributed Crowdsensing Framework"在IEEE TMC正式发表！',
        },
        {
          newUrl: './static/37.TITS-Intelligent.pdf',
          ch: '2021年4月，祝贺我们的论文: "Intelligent Edge Computing in Internet of Vehicles: A Joint Computation Offloading and Caching Solution"在IEEE TITS正式发表！',
        },
        {
          newUrl: './static/34.TPDS-XJ.pdf',
          ch: '2021年2月，祝贺我们的论文: "Multi-Agent Imitation Learning for Pervasive Edge Computing: A Decentralized Computation Offloading Algorithm"在IEEE TPDS正式发表！',
        },
        {
          newUrl: './static/43.JSAC-Mobile.pdf',
          ch: '2021年2月，祝贺我们的论文: "Mobile Edge Computing Enabled 5G Health Monitoring for Internet of Medical Things: A Decentralized Game Theoretic Approach"在IEEE JSAC正式发表！',
        },
        {
          newUrl: './static/33.TMC-Minimizing2.pdf',
          ch: '2021年1月，祝贺我们的论文: "Minimizing the Age-of-Critical-Information: An Imitation Learning-based Scheduling Approach Under Partial Observations"在IEEE TMC正式发表！',
        },
      ],
      /* 学术经历 */
      positions: [
        {
          year: "2021-Present",
          position: "Prefessor 教授",
          schoolEng: "School of Communication and Information Engineering",
          schoolCh: "通信与信息工程学院",
          universityEng: "Chongqing University of Post and Telecommunication",
          universityCh: "重庆邮电大学",
          nationEng: "China",
          nationCh: "中国",
        },
      ],
      /* 荣誉奖励 */
      awards: [
        {
            year: "2024",
            titleCh: "2024 宁兆龙教授入选强国青年科学家引领计划",
        },
        {
            year: "2024",
            titleCh: "2024 宁兆龙教授入选重庆市杰青",
        },
        {
            year: "2024",
            titleCh: "2024 宁兆龙教授入选IET Fellow",
        },
        {
            year: "2023",
            titleCh: "2023 宁兆龙教授入选中组部青年拔尖人才",
        },
        {
          year: "2023",
          titleCh: "2023 宁兆龙教授连续4年入选科睿唯安全球高被引科学家（计算机学科）",
        },
        {
          year: "2023",
          titleCh:
            "2023 王小洁教授入选科睿唯安全球高被引科学家（交叉学科）",
        },
        {
          year: "2023",
          titleCh: "2023 宁兆龙教授参与完成的成果获甘肃省自然科学奖特等奖",
        },
        {
          year: "2022",
          titleCh: "2022 宁兆龙教授牵头完成的成果获中国产学研合作创新成果一等奖",
        },
        {
          year: "2022",
          titleCh: "2022 宁兆龙教授荣获重庆青年五四奖章",
        },
        {
          year: "2022",
          titleCh: "2022 宁兆龙教授获2022年度重庆市十佳科技青年奖",
        },
        {
          year: "2022",
          titleCh: "2022 宁兆龙教授入选全球AI 2000人工智能最具影响力学者",
        },
        {
          year: "2021",
          titleCh: "2021 王小洁教授入选中国科协青年人才托举工程",
        },
      ],
      /* 走马灯图片 */
      /* 科研平台 */
      photos: [
        {
          photoUrl: "http://link.lins-cqupt.cn/platform/uav3.png", //uav3
        },
        {
          photoUrl: "http://link.lins-cqupt.cn/platform/uav2.png", //uav2
        },
        {
          photoUrl: "http://link.lins-cqupt.cn/platform/uav1.png", //uav1
        },
        {
          photoUrl: "http://link.lins-cqupt.cn/platform/基于USRP的信号发射和接收模块.jpg",
        },
        // 大工无人机
        // {
        //   photoUrl: "http://link.lins-cqupt.cn/platform/无人机通信与计算原型系统示意图.png",
        // },
        // 大工共享巴士
        // {
        //   photoUrl: "http://link.lins-cqupt.cn/platform/智能车联网资源调度原型系统示意图.png",
        // },
        {
          photoUrl: "http://link.lins-cqupt.cn/platform/wurenji_che.jpg", // 无人机和车
        },
        {
          photoUrl: "http://link.lins-cqupt.cn/platform/%E6%95%B0%E5%AD%97%E5%AD%AA%E7%94%9F.png", //数字孪生
        },
        {
          photoUrl: "http://link.lins-cqupt.cn/platform/无线智能通信实验平台.jpg",
        },
        {
          photoUrl: "http://link.lins-cqupt.cn/platform/FPGA辅助的光电混合人工智能加速平台.jpg",
        },
        {
          photoUrl: "http://link.lins-cqupt.cn/platform/多无人机.png",
        },
        {
          photoUrl: "http://link.lins-cqupt.cn/platform/5GNR平台.png",
        },
        {
          photoUrl: "http://link.lins-cqupt.cn/platform/5G边缘计算平台.png",
        },
        

      ],
      /* isSmallScreen: false */
    };
  },
};
</script>

<style scoped>

/* 横线上方的emoji */
.emoji {
  margin-top: 40px;
}
/* 实验室介绍 */
.lab-introduciton {
  line-height:32px;
  font-size:17px;
  text-indent: 2em;
}
 /* 实验室招生 */
.recruit  {
  color:rgb(12, 112, 226);
  line-height:32px;
  font-size:17px
}
/* 分割线 */
.dividing-lines {
  position: relative;
  height: 20px;
  margin-bottom: 20px;
  color: rgb(12, 112, 226)
}
/* 伪元素画横线 */
.dividing-lines::after {
  bottom: -20px;
  width: 100%;
  display: block;
  height: 20px;
  opacity: 0.4;
  background-image: url(../assets/logo/line-shadow.png);
  background-color: transparent;

  content: "";
  position: absolute;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
}
/* 小标题前面的icon框框，font-size可以设置emoji大小 */
.icon {
  font-size: 26px;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 33px;
  text-align: center;
  margin-right: 10px;
  border: 1px solid #636363;
  margin-top: 8px;
}

/* 学术经历标题 */
.with-underline {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 30px;
  position: relative;
  border-bottom: 1px solid #dedede;
  padding-bottom: 5px;
}
/* 学术经历的ul */
.with-shaded-label {
  position: relative;
  z-index: 1;
  line-height: 1.5;
  margin-top: 0;
}
/* 每一条学术经历 */
.with-shaded-label li {
  /* 不显示点 */
  list-style: none;
  padding-top: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  display: block;
  text-align: -webkit-match-parent;
}
.title {
  margin-top: 40px 0 0 0;
}
/* 年份，阴影错位效果 */
.shaded-label {
  font-size: 180%;
  margin-left: 0px;
  bottom: 2px;
  position: absolute !important;
  top: 0px;
  left: 0;
  z-index: -1;
  color: #cecece;
}

.news-content {
  line-height: 22px;
}

/********** 荣誉奖励开始 ********/
.subTitle {
  margin: 30px 0 30px 0;
  font-size: 18px;
}
/* 科研奖励box */
.icon-box.ib-v4 .title.with-shaded-label {
  left: 20px;
}
/* 每个box */
.icon-box {
  float: left;
  width: 100%;
  height: 160px;
}
/* 侧边图标样式 */
.icon-box.ib-v4 > i {
  font-size: 35px;
  float: left;
  position: relative;
  top: 40px;
  line-height: 1px;
}
/* 具体内容 */
.icon-box.ib-v4 .title {
  margin-left: 30px;
  margin-bottom: 15px;
  position: relative;
  padding-top: 25px;
  font-size: 15px;
  text-transform: capitalize;
}
/* 年份阴影 */
.icon-box.ib-v4 .shaded-label {
  font-size: 180%;
  margin-left: 0px;
  bottom: 5px;
  position: absolute !important;
  top: -15px;
  left: 0;
  z-index: -1;
  color: #cecece;
}
/********** 科研奖励结束 ********/

/******* 科研平台走马灯效果 *********/
  .award-el-carousel {
    display: block;
    overflow: hidden;
  }
  .small-award-el-carousel{
    display: none;
  }
  .awardPhoto-box {
    width: 100%;
  }
  .awardPhoto-box .el-image {
    width: 100%;
    height: 25vw;
  }

@media (max-width: 1200px) {
  .icon-box {
    width: 100%;
    margin: 15px 0 0 0;
    height: auto;
  }

  /* 显示大的走马灯 */
  .award-el-carousel{
    display: block;
    width: 100%;
    height: 25vh;
    overflow: hidden;
  }
  /* 每个图片的box */
  .awardPhoto-box {
    width: 100%;
    height: 25vh;
  }
  /* box里面的图片 */
  .awardPhoto-box img {
    width: 100%;
    height: 100%;
  }

}

@media (max-width: 450px) {
  /* 隐藏大走马灯 */
  .award-el-carousel {
    display: none;
  }
  /* 显示小走马灯 */
  .small-award-el-carousel{
    display: block;
    width: 100%;
    height: 25vh;
    overflow: hidden;
  }
}
</style>